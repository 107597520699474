import { useState } from "react"
import Tabs from "./Tabs.tsx"

export default function AusstellerPopup({
    exhibitor,
}: {
    exhibitor: {
        name: string
        logoSrc?: string
        imageAdSrc?: string
        fields: Record<string, string>
    }
}) {
    const [activeTab, setActiveTab] = useState("table")

    return (
        <div
            style={{
                background: "white",
                display: "flex",
                flexDirection: "column",
                width: "clamp(300px, 100%, 90dvw)",
                borderRadius: "0.5em",
                fontFamily: "Poppins",
                gridColumn: "1/-1",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    columnGap: "4em",
                    justifyItems: "start",
                    alignItems: "baseline",
                    padding: "3em",
                }}
            >
                {exhibitor.logoSrc ? (
                    <>
                        <img
                            src={exhibitor.logoSrc}
                            width="300"
                            height="100"
                            style={{
                                objectFit: "contain",
                            }}
                        />
                    </>
                ) : (
                    <h3>{exhibitor.name}</h3>
                )}
            </div>
            <div
                style={{
                    paddingInline: "2em",
                    paddingBlock: "1em",
                }}
            >
                {exhibitor.imageAdSrc ? (
                    <Tabs
                        tabs={[
                            { id: "table", label: "Details" },
                            { id: "image", label: "Anzeige" },
                        ]}
                        activeTab={activeTab}
                        onTabSelect={(id) => {
                            setActiveTab(id)
                        }}
                    >
                        <table>
                            <tbody>
                                {Object.entries(exhibitor.fields)
                                    .filter(
                                        ([key, value]) =>
                                            !key.startsWith("@") &&
                                            !["Anlagen", "Inhaltstyp"].includes(
                                                key
                                            )
                                    )
                                    .map(([key, value], index) => (
                                        <tr key={index}>
                                            <td
                                                style={{
                                                    paddingBlock: "0.5em",
                                                    paddingInline: "1em",
                                                    verticalAlign: "top",
                                                }}
                                            >
                                                {key}
                                            </td>
                                            <td
                                                style={{
                                                    paddingBlock: "0.5em",
                                                    paddingInline: "1em",
                                                    verticalAlign: "top",
                                                }}
                                            >
                                                {value}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                        <img
                            src={exhibitor.imageAdSrc}
                            width="700"
                            height="auto"
                            style={{
                                objectFit: "contain",
                            }}
                        />
                    </Tabs>
                ) : (
                    <table>
                        <tbody>
                            {Object.entries(exhibitor.fields)
                                .filter(
                                    ([key, value]) =>
                                        !key.startsWith("@") &&
                                        !["Anlagen", "Inhaltstyp"].includes(key)
                                )
                                .map(([key, value], index) => (
                                    <tr key={index}>
                                        <td
                                            style={{
                                                paddingBlock: "0.5em",
                                                paddingInline: "1em",
                                                verticalAlign: "top",
                                            }}
                                        >
                                            {key}
                                        </td>
                                        <td
                                            style={{
                                                paddingBlock: "0.5em",
                                                paddingInline: "1em",
                                                verticalAlign: "top",
                                            }}
                                        >
                                            {value}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    )
}
